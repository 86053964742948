/*@import url("https://use.typekit.net/gfz7qnl.css");*/
@import url('./assets/css/fonts.css');

/**
 * Normal Font
 * font-family: futura-pt,sans-serif;
 * font-weight: 400;
 * font-style: normal;
 *
 * Bold Font
 * font-family: futura-pt-bold,sans-serif;
 * font-weight: 700;
 * font-style: normal;
 */
body {
  font-family: futura-pt,sans-serif;
  font-weight: 400;
  font-style: normal;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d1d2d4;
  min-height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none;
}

#root {
  /*height: 100%;*/
  /*position: relative;*/
}