.location-bar-Suitcase {
    position: relative;
}

.location-bar-Suitcase-button {
    background: none;
    border: none;
    margin:0;
    padding: 0;
}
.location-bar-Suitcase-button img:first-child {
    margin-bottom: 6px;
}
.location-bar-Suitcase img {
    width: 153px;
}

.location-bar-Suitcase-counter {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
}

.location-bar-Suitcase-counter span {
    background-color: #ed1c24;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1em;
    margin-left: -50%;
    padding: 16px 0;
    text-align: center;
    width: 50px;
}