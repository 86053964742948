.Rack {
    box-shadow: 0 0 8px #b8b8ba;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
}

.Rack-left-arrow, .Rack-right-arrow {
    background: none;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50px;
}

.Rack-left-arrow {
    left: -23px;
}
.Rack-right-arrow {
    right: -23px;
}