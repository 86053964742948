.Button {
    background-color: #d8e1ee;
    background-image: -webkit-linear-gradient(top, #f7f8f9,#eff3f8,#d8e1ee);
    background-image: -moz-linear-gradient(top, #f7f8f9,#eff3f8,#d8e1ee);
    background-image: -ms-linear-linear-gradient(top, #f7f8f9,#eff3f8,#d8e1ee);
    background-image: -o-linear-gradient(top,#f7f8f9,#eff3f8,#d8e1ee);
    background-image: linear-gradient(to bottom,#f7f8f9,#eff3f8,#d8e1ee);
    border: 1px solid #abb0b6;
    border-radius: 2px;
        -webkit-border-radius: 2px;
    box-sizing: border-box;
    color: #444;
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    /*margin: 0 2px 4px;*/
    padding: 10px;
    text-align: center;
    text-decoration: none;
    /*text-transform: uppercase;*/
}
.Default.Button {
    box-shadow: inset 0 -3px 5px #eee;
    text-shadow: 0 0 1px #bbb;
}

.Default.Button:hover {
    background: #f7f8f9;
}

.Primary.Button {
    background: #FAC406;
    background-image: -moz-linear-gradient(top, #FAC406 0%, #ED9F15 25%, #DC781D 100%);
    background-image: -webkit-gradient(left top, left bottom, color-stop(0%, #FAC406), color-stop(25%, #ED9F15), color-stop(100%, #DC781D));
    background-image: -webkit-linear-gradient(top, #FAC406 0%, #ED9F15 25%, #DC781D 100%);
    background-image: -o-linear-gradient(top, #FAC406 0%, #ED9F15 25%, #DC781D 100%);
    background-image: -ms-linear-gradient(top, #FAC406 0%, #ED9F15 25%, #DC781D 100%);
    background-image: linear-gradient(to bottom, #FAC406 0%, #ED9F15 25%, #DC781D 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fac406', endColorstr='#dc781d', GradientType=0);
    border: 1px solid #DC781D;
    /*border-radius: 2px;*/
    color: #000;
    /*font-size: 12px;*/
    /*padding: 5px;*/
    position: relative;
}

.Primary.Button:hover {
    background-color: rgb(250, 196, 6);
    background-image: none;
}

.Primary.Button::after {
    content: ' ';
    background-color: rgb(231, 158, 15);
    position: absolute;
    left: 3px;
    right: 3px;
    bottom: 2px;
    height: 2px;
}

.BrochureTab.Button {
    background:#0295ce;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
    border-color:#0170a8;
    box-shadow:inset 0 -3px 5px #0093cf;
    color:#fff;
    cursor: default;
    padding: 11px 10px;
    margin-bottom:0;
    position: relative;
    text-shadow:0 0 2px #bbb;
}

.Enabled.BrochureTab.Button {
    background-image: linear-gradient(to bottom, #01abe6, #0295ce, #0295ce);
    cursor: pointer;
}

.Enabled.BrochureTab.Button:hover {
    background-color: #01abe6;
    background-image: none;
    border-bottom: none;
}

.Selected.BrochureTab.Button {
    border-bottom: none;
}

.Disabled.BrochureTab.Button {
    opacity: 0.7;
    font-style: italic;
    outline: none;
}

.BrochureTab.Brochure:after {
    background-color: #0d79b1;
    bottom: 3px;
    box-shadow: 0 0 1px #0d79b1;
    content:" ";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
}

.HotDeal.Button {
    background: #FAC406;
    background-image: -moz-linear-gradient(top, #AFD42E 0%, #73B33B 25%, #529434 100%);
    background-image: -webkit-gradient(left top, left bottom, color-stop(0%, #AFD42E), color-stop(25%, #73B33B), color-stop(100%, #529434));
    background-image: -webkit-linear-gradient(top, #FAC406 0%, #73B33B 25%, #529434 100%);
    background-image: -o-linear-gradient(top, #AFD42E 0%, #73B33B 25%, #529434 100%);
    background-image: -ms-linear-gradient(top, #AFD42E 0%, #73B33B 25%, #529434 100%);
    background-image: linear-gradient(to bottom, #AFD42E 0%, #73B33B 25%, #529434 100%);
    border-color: #45843A;
    color: #000;
    position: relative;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: bold;
}
.HotDeal.Button:hover {
    background-color: #aaa;
}

.HotDeal.Button:after {
    content: ' ';
    background-color: #AED230;
    position: absolute;
    left: 3px;
    right: 3px;
    bottom: 2px;
    height: 2px;
}

.FullWidth.Button {
    display: block;
    width: 100%;
}

.LeftAlign.Button {
    text-align: left;
}

.BrochureWebsite.Button {
    background: #1b5b77;
    border: none;
    border-radius: 3px;
    color: #fff;
    text-transform: uppercase;
}

.ChangeLocation.Button {
    background-color: #1cace1;
    background-image: none;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}