.rack-Footer {
    display: flex;
}
.rack-Footer-support {
    background-color: #f6dfa2;
    width: 7px;
}
.rack-Footer-center {
    background-image: url(../../../../assets/img/racks/WoodTexture.jpg);
    background-position: center;
    /*box-shadow: inset 0px 1px 4px -1px;*/
    flex-grow: 1;
    /*margin-bottom: 5px;*/
    text-align: center;
}
.rack-Footer-body {
}
.rack-Footer-shadow {
    background-color: #b8b8ba;
    height: 5px;
}
.rack-Footer-brand {
    background-color: #dc9;
    /*background-color: #baa976;*/
    mix-blend-mode: hard-light;
}

.rack-Footer-inset {
    border: 1px solid #ba7;
    box-shadow: inset 0px 1px 4px -1px;
    padding: 5px;
}
.rack-Footer-inset .rack-Footer-inset {
    /*background-color: #baa976;*/
    /*background-color: #f6dfa2;*/
}

.rack-Footer-provider {
    background: none;
    border: none;
    display: inline-block;
    margin: 0;
    outline: none;
    padding: 0;
}
.rack-Footer-provider img {
    max-height: 28px;
}