.Initialization-ip {
    /*background-color: #fff;*/
    border: 1px solid #999;
    border-radius: 3px;
    margin-top: 10px;
    text-align: left;
}
.Initialization-ip label, .Initialization-ip span {
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
}
.Initialization-ip label {
    background-color: #e1e1e1;
    color: #09f;
    font-weight: bold;
}

.Initialization-ip span {
    color: #000;
}
