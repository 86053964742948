.setup-Kiosks-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.setup-Kiosks-list li {
    margin: 10px 0;
    padding: 0;
}

.setup-Kiosks-kiosk {
    background-color: #09c;
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    width: 100%;
}
.setup-Kiosks-kiosk:active {
    background-color: #06a;
}

.setup-Kiosks-kiosk .label {
    flex-grow: 1;
    height: 32px;
    line-height: 32px;
    padding: 15px;
    vertical-align: center;
}
.setup-Kiosks-kiosk .arrow {
    background-color: #06a;
    flex-shrink: 1;
    line-height: 32px;
    padding: 15px 20px;
    text-align: center;
}
