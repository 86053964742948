.brochure-ButtonTabbedContent-window {
    background-color: #00b2ed;

    /*background-image: -webkit-linear-gradient(top, #01abe6, #0295ce, #0090cb);*/
    /*background-image: -moz-linear-gradient(top, #01abe6, #0295ce, #0090cb);*/
    /*background-image: -ms-linear-gradient(top, #01abe6, #0295ce, #0090cb);*/
    /*background-image: -o-linear-gradient(top, #01abe6, #0295ce, #0090cb);*/
    /*background-image: linear-gradient(to bottom, #01abe6, #0295ce, #0090cb);*/
    background-image: linear-gradient(to bottom, #0295ce, #00b2ed, #026b9f);

    border: 1px solid #0170a8;
    border-top: none;

    padding: 20px;
    position: relative;
}

.brochure-ButtonTabbedContent-window:after {
    content: ' ';
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00b2ed;
}

.brochure-ButtonTabbedContent-content {
    background-color: #e1e1e1;
    color: #444;
    border: 3px solid #fff;
    min-height: 200px;
}
