button.rack-Brochure {
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    padding: 0 7px;
    width: 100%;
}

.rack-Brochure-container {
    padding-top: 225%;
    position: relative;
}

.rack-Brochure-container img {
    border: none;
    height: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}