.ScreenTakeover {
    background-color: rgba(0, 0, 0, 0.95);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #fff;
}

.ScreenTakeover-video-container {
    background-color: #333;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.ScreenTakeover video {
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 0;
}