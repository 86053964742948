.HotDeals h1 {
    color: #003049;
    font-style: italic;
}
.HotDeals-deals {
    display: flex;
    flex-wrap: wrap;
}

.HotDeals-deal {
    display: inline-block;
    background: none;
    margin: 0 20px 20px 0;
    padding: 0;
    width: 187px;
}

.HotDeals-deal img {
    max-width: 100%;
}