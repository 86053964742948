.location-bar-Categories {
    /*padding: 16px 5px 3px 13px;*/
}

.location-bar-Categories h2 {
    font-size: 12px;
    font-weight: normal;
    margin: 0 0 10px;
    text-align: center;
    text-transform: uppercase;
}

.location-bar-Category {
    margin: 10px 0;
}
/*
.location-bar-Categories ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.location-bar-Categories li {
    margin: 2px 0;
    padding: 0;
}
.location-bar-Categories li button {
    background: none;
    display: flex;
    line-height: 1em;
    padding: 0;
    text-align: left;
    width: 100%;
}
.location-bar-Categories li button i {
    border: 1px solid transparent;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 10px;
    margin: 0px 11px 0 0;
    overflow: hidden;
    width: 10px;
}
.location-bar-Categories li.selected button i {
    background-color: #1cace1;
    border: 1px solid #bdbbbc;
    margin-top: 10px;
}

.location-bar-Categories li button span {
    border-top: 1px solid #f0f0f0;
    flex-grow: 1;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2em;
    padding: 10px 0;
}
.location-bar-Categories li.selected button span {
    color: #1cace1;
}
*/