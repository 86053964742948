.suitcase-Brochure {
    display: flex;
}

.suitcase-Brochure p {
    margin: 0 0 10px;
}

.suitcase-Brochure-image {
    padding-right: 20px;
    width: 100px;
}

.suitcase-Brochure-image img {
    max-width: 100%;
}

.suitcase-Brochure-content {
    flex-grow: 1;
}
