.App {}

.App.hd {
    width: 1920px;
    height: 1080px;
}

.App-loading {
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.App-loading .App-loading-details {
    margin: 10px auto;
    max-width: 100%;
    position: absolute;
    left: 50%;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.App-loading-details .logo {
    width: 200px;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}