.template-Flag {
    background: none;
    border: 0;
    cursor: pointer;
    height: 26px;
    margin: 0 7px 7px 0;
    outline: none;
    overflow: hidden;
    padding: 0;
    vertical-align: middle;
    width: 39px;
}

.template-Flag:last-child {
    margin-right: 0;
}

.template-Flag:hover {
    box-shadow: 0 0 5px #fc0;
}

.template-Flag.selected {
    box-shadow: 0 0 5px #0193ce;
}

.template-Flag img {
    height: 26px;
    width: 39px;
    vertical-align: middle;
}