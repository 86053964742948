.Suitcase {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
}
.Suitcase.empty {
    text-align: center;
}

.Suitcase-brochure-detail {
    width: 450px;
}

.Suitcase-container {
    display: flex;
    flex-wrap: wrap;
}
.Suitcase-container .Suitcase-brochures {
    flex-grow: 1;
}
.Suitcase-container .Suitcase-download {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 250px;
}

.Suitcase-container .Suitcase-download h2 {
    margin: 0;
}