.ScreenCropBox {
    align-items: center;
    border: 2px solid #c00;
    background-color: rgba(255, 0, 0, .2);
    display: flex;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
}

.ScreenCropBox-label {
    font-size: 3rem;
}