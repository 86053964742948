.LayoutBuilder, .LayoutBuilder-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.LayoutBuilder-children, .LayoutBuilder-component {
    position: absolute;
    width: 100%;
    height: 100%;
}

.LayoutBuilder-close {
    position: absolute;
    right: 20px;
    top: 20px;
}