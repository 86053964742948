.rack-Row {
    align-items: flex-end;
    display: flex;
    padding-top: 3px;
    position: relative;
}
.rack-Row-shadow {
    bottom: 0;
    left: 0;
    height: 20px;
    position: absolute;
    right: 0;
    box-shadow: inset 0 -10px 10px -5px rgba(0, 0, 0, 0.8);
    /*!*box-shadow: inset 0 -10px 10px 20px rgba(100, 100, 100, 0.5);*!*/
    pointer-events: none;
}