.ScreenSelector {
    background-color: #fff;
    padding: 20px 20px 10px;
}
.ScreenSelector-description {
    color: #09f;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
.ScreenSelector-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ScreenSelector .ChangeLocationScreen {
    margin: 5px;
}