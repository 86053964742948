.setup-CodeValue {
    background-color: #444;
    border-radius: 5px;
    box-shadow: inset 0 0 3px #000;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #09f;
    cursor: pointer;
    font-size: 24px;
    padding: 5px 10px;
    width: 50px;
    user-select: none;
}

.setup-CodeValue.selected {
    background-color: #444;
    box-shadow: 0 0 10px #09f;
    border-color: #000;
}