.VisitorTips {
}
.VisitorTips-debug {
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px;
  background-color: #c00;
  color: #fff;
}
.VisitorTips-background {
  transition: background 1s;
  position: absolute;
  z-index: -1;
  background-size: cover;
  box-sizing: border-box;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.4;
}

.VisitorTips-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.VisitorTips-body {
  display: flex;
  padding-top: 33px;

}

.VisitorTips-link {
  color: #09d3ac;
}

.VisitorTips-leftbar {
  /*box-sizing: border-box;*/
  /*padding-left: 12px;*/
  padding-left: 74px;
  /*width: 201px;*/
  width: 237px;
}

.VisitorTips-leftbar .AdminCodeButton {
  border: none;
  border-radius: 3px;
  background: rgba(255, 255, 0, 0.2);
  padding: 0;
  height: 50px;
  width: 100%;
}

.VisitorTips-main {
  flex-grow: 1;
  margin: 0 65px;
}
.VisitorTips-brochure {
  flex-grow: 1;
  padding: 0 20px 20px;
}

.VisitorTips-rightbar {
  padding-right: 68px;
  width: 220px;
}

.VisitorTips-location-bar {
  margin: 0 0 10px;
}

.VisitorTips-hot-deals {
  margin: 10px 0px;
}

.VisitorTips-mobile-apps {
  text-align: center;
}

.VisitorTips-mobile-apps button {
  margin: 5px 4px;
}

.body-shadow-small {
  box-shadow: 0 0 5px 1px #003c50;
  -webkit-box-shadow: 0 0 5px 1px #003c50;
}

.LocationRack-suitcase {
  background-color: #fff;
  box-sizing: border-box;
  padding: 6px 24px 24px;
  margin: 25px auto 32px;
  width: 201px;
}

button {
  background: none;
  border: none;
  margin: 0;
  outline: none;
  padding: 0;
  cursor: pointer;
}

/*rack left margin = 210px*/
/*left bar*/
                      /*width 160*/
                      /*left 10px*/