.CodePad-value {
    display: flex;
    justify-content: center;
}

.CodePad-value-box {
    margin: 0 5px;
}

.CodePad-button-row {
    display: flex;
    margin: 10px 0;
}