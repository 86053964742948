.LocationBar {
    /*background-color: #0193ce;*/
    /*border: 2px solid #0193ce;*/
    /*border-radius: 3px;*/
    /*box-shadow: 0 0 10px 1px #003c50, inset 0 4px 8px -6px #036;*/
    /*color: #fff;*/
    /*padding: 5px 3px;*/
}

.LocationBar-logo {
    width: 237px;
    max-width: 100%;
}

/*.LocationBar-module {*/
/*    margin: 0 0 24px;*/
/*}*/
/*.white.LocationBar-module {*/
/*    background-color: #fff;*/
/*}*/

.LocationBar-change {
    margin-bottom: 42px;
}

.location-bar-Categories {
    /*margin: 20px 0 0;*/
}

.LocationBar h3 {
    color: #000;
    font-weight: normal;
    margin: 0 0 10px;
    text-align: center;
}
.LocationBar h3 strong {
    color: #0b96e0;
    font-weight: bold;
}