/**
 * Paste file from Adobe below.  Then run fonts.php at the root to generate fonts.css
 */
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * futura-pt:
 *   - http://typekit.com/eulas/000000000000000000013365
 * futura-pt-bold:
 *   - http://typekit.com/eulas/00000000000000003b9af1e4
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-04-15 15:32:27 UTC"}*/

/*@import url("https://p.typekit.net/p.css?s=1&k=gfz7qnl&ht=tk&f=10884.32874&a=42005178&app=typekit&e=css");*/

@font-face {
    font-family:"futura-pt";
    src:url("../fonts/futura-pt.woff2") format("woff2"),url("../fonts/futura-pt.woff") format("woff"),url("../fonts/futura-pt.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
    font-family:"futura-pt-bold";
    src:url("../fonts/futura-pt-bold.woff2") format("woff2"),url("../fonts/futura-pt-bold.woff") format("woff"),url("../fonts/futura-pt-bold.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
}

.tk-futura-pt { font-family: "futura-pt",sans-serif; }
.tk-futura-pt-bold { font-family: "futura-pt-bold",sans-serif; }