.Brochure {
    color: #005bae;
    display: flex;
    line-height: 1.7em;
}

.Brochure-media {
    line-height: 1em;
    padding-right: 30px;
    width: 300px;
}

.Brochure-media img {
    max-width: 100%;
}

.Brochure-media .external-id {
    color: #333;
    font-size: .9em;
}

.Brochure-details {
    flex-grow: 1;
    max-width: 600px;
}

.Brochure .section {
    margin: 0 0 20px;
}

.Brochure h1 {
    margin: 30px 0 10px;
}

.Brochure-contact, .Brochure-external-section {
    display: flex;
}

.Brochure-external-section .Brochure-external {
    width: 500px;
    max-width: 100%;
    margin-right: 5px;
}

.Brochure-external-section .Brochure-social {
    width: 36px;
}

.Brochure-contact .Brochure-address,
.Brochure-contact .Brochure-phone {
    flex: 1 1 0px;
}

.Brochure-button-row {
    display: flex;
    margin-right: 50px;
}
.Brochure-button-row button {
    flex-grow: 1;
    margin: 0 2px;
}
.Brochure-button-row button:first-child {
    margin-left: 0;
}
.Brochure-button-row button:last-child {
    margin-right: 0;
}

.Brochure label {
    /*color: #94d4ed;*/
    color: #005bae;
    font-size: 1.2em;
    font-weight: bold;
}

.Brochure-social-button {
    background: none;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}
.disabled.Brochure-social-button {
    opacity: 0.3;
}
