.Modal-Overlay {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    background-color: rgba(0, 45, 69, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-x: hidden;
    z-index: 100;
}
.Modal {
    /*background-color: #fff;*/
    /*border-radius: 5px;*/
    color: #fff;
    min-width: 500px;
    min-height: 300px;
    margin: 0 auto;
    /*padding: 30px;*/
}

/*.Modal-fill-parent {*/
    /*position: absolute;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
/*}*/