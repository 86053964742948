.ResourceBar {
    /*background-color: #0193ce;*/
    /*border: 1px solid #39c;*/
    /*border-top-left-radius: 5px;*/
    /*border-bottom-left-radius: 5px;*/
    /*box-shadow: 0 0 10px 1px #003c50, inset 0 4px 8px -6px #036;*/
    /*color: #fff;*/
    /*padding: 7px 0;*/
}
.ResourceBar h2 {
    /*color: #feef32;*/
    /*margin: 0;*/
    /*text-align: center;*/
    /*font-size: 22px;*/
}

.ResourceBar-mock-banner, .ResourceBar-banner {
    height: 830px;
    width: 220px;
}

.ResourceBar-mock-banner {
    background-color: #888;
    border: 7px solid #fff;
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
}

.ResourceBar-mock-banner div {
    color: #fff;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}