.setup-CodeButton {
    border: 1px solid #000;
    border-radius: 3px;
    cursor: pointer;
    flex-basis: 1px;
    flex-grow: 1;
    margin: 0 5px;
    padding: 10px;
}

.setup-CodeButton:hover {
    background-color: #ccc;
}

.setup-CodeButton:first-child { margin-left: 0; }
.setup-CodeButton:last-child { margin-right: 0; }