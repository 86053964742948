.LoadingSpinner {
    display: inline-block;
}
.LoadingSpinner img {
    animation:spin 0.5s linear infinite;
    vertical-align: middle;
}

.slow-speed.LoadingSpinner img {
    animation:spin 3s linear infinite;
}

.normal.LoadingSpinner img {
    height: 24px;
    width: 24px;
}

.large.LoadingSpinner img {
    height: 64px;
    width: 64px;
}

.medium.LoadingSpinner img {
    height: 32px;
    width: 32px;
}

.small.LoadingSpinner img {
    height: 16px;
    width: 16px;
}

.LoadingSpinner label {
    color: #ff0;
    display: inline-block;
    font-weight: bold;
    margin-left: 10px;
}
@keyframes spin {
    100% { transform:rotate(360deg); }
}