.Header {
    background-color: #fff;
    display: flex;
    height: 145px;
    /*position: relative;*/
}

.Header-logo-button {
    border: none;
    background: none;
    cursor: default;
    /*margin: 42px 47px 0 86px;*/
    margin: 0 47px 0 86px;
    padding: 0;
}
.Header-logo {
    height: 74px;
    /*margin: 16px 0 2px;*/
    width: 317px;
}
.Header-brand-logo {
    height: 100px;
    max-width: 317px;
}
.Header-left {
    align-self: center;
}
.Header-center {
    align-self: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    position: relative;
}
.Header-change-location strong {
    font-size: 24px;
}
.Header-change-buttons button {
    margin: 5px 5px;
}
.Header-cobrand-divider {
    background-color: #000;
    height: 80px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
}
.Header-cobrand-content {
    min-width: 300px;
    /*padding: 40px 30px;*/
    /*text-align: center;*/
}
.Header-cobrand-content img {
    max-width: 100%;
    max-height: 100px;
}
.Header-social button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
}

.Header-right {
    margin-right: 84px;
}
.Header-fun {
    color: #005eaf;
    /*font-family: Futura;*/
    font-size: 35px;
    letter-spacing: 1px;
    line-height: 1em;
    margin: 30px 0 18px;
    text-align: center;
}

@media all and (max-width: 1600px) {
    .Header-fun {
        font-size: 24px;
    }
}

.Header-fun.i18n-text-en {
    text-transform: uppercase;
}

.Header-flags {
    text-align: center;
}