.rack-MockBrochure {
    /*background-color: rgba(200, 200, 200, 0.2);*/
    /*border:3px dashed #999;*/
    background-color: rgba(200, 200, 200, 0.5);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100px;
    max-width: 100%;
    height: 225px;
    /*opacity: 0.5;*/
}

.rack-MockBrochure-loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}