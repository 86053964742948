.Dialog {
    /*background-color: #eee;*/
    background-color: #e7ecf2;
    /*border:1px solid #666;*/
    border-radius: 3px;
    /*box-shadow: 0 0 10px #09a;*/
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.Dialog.has-header {
    padding-top: 0;
}

.Dialog-header {
    padding: 10px 0;
    position: relative;
}
.Dialog-header.has-title {
    border-bottom: 1px solid #cce;
}

.Dialog-header label {
    font-weight: bold;
    color: #333;
}

.Dialog-fill-parent {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.Dialog-close {
    /*background-image: linear-gradient(to bottom, #01abe6, #0295ce, #0295ce);*/
    /*background-color: #f99;*/
    /*border: 1px solid #c66;*/
    background-color: #e7ecf2;
    border: 1px solid #99b;
    border-radius: 50%;
    box-shadow: 1px 1px #fff;
    cursor: pointer;
    height: 24px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0px;
    top: 3px;
    width: 24px;
}

.Dialog-close:hover {
    background-color: #003349;
}

.Dialog-close span {
    /*color: #f00;*/
    /*color: #aac;*/
/*rgba(0, 45, 69, 0.8);*/
    color: #003349;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 1px 1px #adf;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-55%);
}

.Dialog-close:hover span {
    color: #fff;
}


.Dialog-body {
    /*border:1px solid #aaa;*/
    /*border-raidius: 3px;*/
    /*font-weight: bold;*/
    color: #000;
    flex-grow: 1;
}